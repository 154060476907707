var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"vJLBX8Gviw4ovAStNLOWK"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { getSession } from './src/utils/getSession';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://284a9fe46a724a1eb7e7e56fa8e853de@o566338.ingest.sentry.io/6521733',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.0,
        beforeSend(event) {
            event.user = getSession();
            return event;
        },
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /^-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /s\.ytimg\.com/i,
            /dgnria2-at-nuance-dot-com/i,
            /script\.hotjar\.com/i,
            /kaspersky-labs\.com/,
            /wistia\.com/,
            /io\.clickguard\.com/,
            /www\.gstatic\.com/,
            /cdn\.livechatinc\.com/,
            /appmakedev\.xyz/,
            /workdevapp\.com/,
            /untsorce\.cool/,
            /www\.googletagmanager\.com/,
            /s0\.2mdn\.net/,
            /[a-z]*\.googleapis\.com/,
            /static\.adsafeprotected\.com/,
            /surveys-static\.survicate\.com/,
            /com\.lge\.browser/,
            /rgvqcsxqge\.com/,
            /countmake\.cool/,
            /appcues\.com/,
            /getbeamer\.com/,
            /newrelic\.com/,
            /ehtaddonpromo/,
        ],
    });
}
